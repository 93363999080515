import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	Navbar,
	NavItem,
	NavbarToggler,
	Collapse,
	NavLink,
	Nav,
	NavbarBrand
} from 'reactstrap';

function App() {

	// Collapse isOpen State
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div style={{
			display: 'relative', width: '100%', padding: 15, 
		}}>
			
			<Navbar color="info" info >
				<NavbarBrand href="/"><center>Mahakalyan</center></NavbarBrand>
				{/*<NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="mr-auto" navbar>
						<NavItem>
							<NavLink href="/MostLikedPost">मानक विश्लेषण</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/MostCommentPost">विदेशी समीकरण</NavLink>
						</NavItem>
            <NavItem>
							<NavLink href="#">सर्वाधिक मान्य विषय </NavLink>
						</NavItem>
            <NavItem>
							<NavLink href="#">सर्वाधिक टिप्पणी किये गए विषय</NavLink>
						</NavItem>
            
					</Nav>
	</Collapse> */}
			</Navbar>
		</div >
	);
}

export default App;
