import React from "react";
import './Page2.css'

export const Page6 = () => {
return (

	<div class="shi1" >
    
	  <div className=" p-5 ">
	<div className="cardProfile">
	<h1 classname="title">निःशुल्क शिक्षा</h1>
	</div>
<div><p></p></div>

<div className="card">
	<p classname="mainContent mx-auto">भारतीय निःशुल्क शिक्षा :-</p>
	<p>शिक्षा एक महत्वपूर्ण अधिकार है जो हर व्यक्ति को उसके निर्माण में सक्षम बनाता है। यहाँ भारतीय समाज के लिए निःशुल्क शिक्षा की महत्वपूर्ण भूमिका है। शिक्षा न केवल व्यक्ति को उसकी स्थिति में सुधार करने में मदद करती है, बल्कि यह समाज की प्रगति और विकास में भी महत्वपूर्ण भूमिका निभाती है।
भारत में निःशुल्क शिक्षा का सिद्धांत वेदों के समय से ही था, जहाँ गुरुकुल पद्धति के अंतर्गत शिक्षा दी जाती थी। वहाँ शिष्य अपने गुरु के आश्रम में रहते थे और निशुल्क शिक्षा प्राप्त करते थे।
भारतीय स्वतंत्रता संग्राम के बाद, नेताओं ने निःशुल्क और उच्च शिक्षा के प्रति अपनी प्राथमिकता को पुनः साबित किया। इसके परिणामस्वरूप, भारत ने विभिन्न क्षेत्रों में विश्वस्तरीय शिक्षा संस्थानों की स्थापना की।
भारतीय संविधान ने निशुल्क और अनिवार्य शिक्षा को एक मूलभूत अधिकार के रूप में स्थापित किया है। इसका मकसद है विभिन्न वर्गों और समुदायों के लोगों को शिक्षा के लाभ से समृद्ध करना।
आधुनिक युग में, निःशुल्क शिक्षा के माध्यम से आधुनिक तकनीकी और वैज्ञानिक शिक्षा उपलब्ध होने चाहिए। इससे गरीबों और वंचित वर्गों के लोगों को विश्वस्तरीय शिक्षा का लाभ मिलेगा और उनके जीवन की गुणवत्ता में सुधार होगा।
निःशुल्क शिक्षा भारतीय समाज की सामाजिक और आर्थिक समर्थता को बढ़ाने में महत्वपूर्ण भूमिका निभाती है और उसे आधुनिक जगत के साथ संगत बनाने में मदद करती है। इसलिए, निःशुल्क शिक्षा को बढ़ावा देना और उसकी पहुंच को विस्तारित करना हम सभी का कर्तव्य है।

स्वतंत्रता के बाद, भारत में निशुल्क शिक्षा को बढ़ावा देने के लिए कई अभियान चलाए गए हैं। इनमें से कुछ प्रमुख अभियान निम्नलिखित हैं:
•	सर्व शिक्षा अभियान (Sarva Shiksha Abhiyan): सर्व शिक्षा अभियान 2001 में शुरू किया गया था और इसका मुख्य उद्देश्य था कि हर बच्चे को बुनियादी शिक्षा प्राप्त हो। इसके तहत, निशुल्क और अनिवार्य शिक्षा के लिए लोगों को प्रोत्साहित किया गया।

•	राष्ट्रीय उदार शिक्षा अभियान (Rashtriya Uchchatar Shiksha Abhiyan): इस अभियान का मुख्य उद्देश्य उच्च शिक्षा के क्षेत्र में समानता और गुणवत्ता को बढ़ावा देना है। इसके अंतर्गत, निशुल्क और उच्च शिक्षा के लिए बड़े पैमाने पर प्रोत्साहन दिया जा रहा है।

•	मिध डे मील एबिलिटी एंड लर्निंग (MDM) स्कीम: इस योजना के अंतर्गत, प्राथमिक और उच्चतर प्राथमिक स्तर के विद्यालयों में शिक्षार्थियों को निःशुल्क भोजन प्रदान किया जाता है। इसका मुख्य उद्देश्य उपयुक्त पोषण प्रदान करना है, ताकि वे शिक्षा में सक्षम हो सकें।
•	संचार मंत्रालय द्वारा निशुल्क कंप्यूटर शिक्षा (NCE): यह अभियान भारत में कंप्यूटर शिक्षा के लिए साधारित की जा रही है। इसका उद्देश्य बेरोजगारी को कम करना और जीविका विकल्प प्रदान करना है।
</p>
	</div>
	<div><p></p></div>



<div className="card3">
	<p classname="mainContent mx-auto">निशुल्क शिक्षा के लिए महत्वपूर्ण योगदान देने वाले कुछ महत्वपूर्ण व्यक्तित्व निम्नलिखित हैं:</p>
	<p>•	मौलाना अबुल कलाम आज़ाद: भारतीय स्वतंत्रता संग्राम के प्रमुख नेता और भारतीय निश्चय के पहले शिक्षा मंत्री के रूप में, मौलाना आज़ाद ने निशुल्क और अनिवार्य शिक्षा को बढ़ावा देने के लिए महत्वपूर्ण योगदान दिया। उन्होंने शिक्षा को समाज के हर वर्ग के लिए उपलब्ध कराने के लिए कई योजनाएं और नीतियां शुरू की।
•	डॉ. सर्वपल्ली राधाकृष्णन: भारतीय विचारक, शिक्षाविद्, और प्रथम राष्ट्रपति के रूप में, डॉ. राधाकृष्णन ने निशुल्क और सामान्य शिक्षा के प्रमोशन के लिए अपना योगदान दिया। उन्होंने शिक्षा के महत्व को समझाने और उसे उपलब्ध कराने के लिए अपने व्याख्यानों और लेखों के माध्यम से समाज को जागरूक किया।
•	डॉ. आब्दुल कलाम: पूर्व भारतीय राष्ट्रपति डॉ. अब्दुल कलाम ने निशुल्क और उच्च शिक्षा के क्षेत्र में अपना महत्वपूर्ण योगदान दिया। उन्होंने शिक्षा के माध्यम से विज्ञान और प्रौद्योगिकी को बढ़ावा देने के लिए कई योजनाएं और पहल की

</p>
	</div>
	<div><p></p></div>


	<div className="card">
	<p classname="mainContent mx-auto">निःशुल्क शिक्षा के लिए महत्वपूर्ण योगदान कई तरह के हो सकते हैं, जिनमें से कुछ महत्वपूर्ण योगदान निम्नलिखित हैं:</p>
	<p>•	सरकारी योजनाएं और नीतियाँ: सरकारों को निशुल्क शिक्षा को प्रोत्साहित करने और समर्थित करने के लिए नीतियों और योजनाओं की आवश्यकता होती है। सरकारी स्तर पर निःशुल्क शिक्षा को वित्तीय सहायता और अन्य संसाधनों के माध्यम से प्रदान किया जाता है।
•	शिक्षा अधिकार: निःशुल्क और अनिवार्य शिक्षा को संविधान में मूलभूत अधिकार के रूप में स्थापित किया गया है। इससे समाज के हर वर्ग के लोगों को शिक्षा के प्रति अधिकार मिलता है।
•	सामाजिक संगठन: सामाजिक संगठन और गैर-सरकारी संगठन भी निशुल्क शिक्षा को प्रोत्साहित करने और प्रदान करने में महत्वपूर्ण योगदान कर सकते हैं। वे अन्याय के खिलाफ लड़कर, गरीब और वंचित वर्गों के लिए शिक्षा के अधिकार की लड़ाई लड़ते हैं।
•	व्यक्तिगत योगदान: व्यक्तिगत स्तर पर, लोग निःशुल्क शिक्षा के प्रोत्साहन में अपना योगदान दे सकते हैं। वे गरीब बच्चों को शिक्षा के लिए आर्थिक सहायता प्रदान करके, उन्हें स्कूलों और कॉलेजों में शामिल होने में मदद कर सकते हैं।
•	संगठनों और अभियानों का समर्थन: निशुल्क शिक्षा के लिए काम करने वाले संगठनों और अभियानों का समर्थन करना भी महत्वपूर्ण है। इन संगठनों के द्वारा निशुल्क शिक्षा के लिए जागरूकता बढ़ाई जा सकती है और लोगों


</p>
	</div></div>
	<div><p></p></div>

	
	




</div>
);
};

export default Page6