import React, {useState, useEffect, useCallback} from 'react'
import PostCard from '../../components/postCard/PostCard'
import NavigationBar from '../../components/NavBar/NavigationBar'
import './PostPage.css'
import Comment from '../../components/comments/Comment'
import db from '../../utils/db.json'
function PostPage({match}) {
  const [post, setPost] = useState({})
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchPost = useCallback(id => {
    const post = db.posts[id]
    console.log({post})
    setPost({...post})
  }, [])

  const fetchComments = useCallback(id => {
    console.log('Before fetch comment ')
    const data = db.comments.filter(
      coment => parseInt(coment.postId) === parseInt(id),
    )
    console.log('After fetch comment ', data)
    setLoading(false)
    setComments([...data])
  }, [])

  useEffect(() => {
    fetchPost(match.params.postId)
  }, [fetchPost, match.params.postId])

  useEffect(() => {
    fetchComments(match.params.postId)
  }, [fetchComments, match.params.postId])

  return (
    <div>
      <p></p>
      {post.title === undefined ? (
        <h1>Loading....</h1>
      ) : (
        <PostCard
         
          post={post}
        />
      )}

      
      
    </div>

    
  )
}

export default PostPage
