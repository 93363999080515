import React from "react";
import './Page1.css'

export const Page1 = () => {
return (
	<div classname="shi1222">
	<div classname="shi1222">
		<p></p>
	<div className="cardProfile">
	<h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;अंतरराष्ट्रीय विषय</h1>
	</div></div></div>
);
};

export default Page1