import React from "react";
import './Page6.css'

export const Page6 = () => {
return (

	<div class="shi1" >
    
	  <div className=" p-5 ">
	<div className="cardProfile">
	<h1 classname="title">भारतीय राजनीति</h1>
	</div>
<div><p></p></div>
<div className="card">
	<p classname="mainContent mx-auto">1900 से पूर्वार्द्ध भारतीय राजनीति :

भारतीय कांग्रेस की स्थापना 1885 में ब्रिटिश भारत के राज्यपाल लॉर्ड डफरिन की नेतृत्व में कोलकाता में हुई थी। यह एक राष्ट्रीय स्तर की भारतीय स्वतंत्रता संग्राम की पहली आधारशिला थी। इसे भारतीय राष्ट्रीय कांग्रेस के नाम से जाना जाता है। इसका मुख्य उद्देश्य ब्रिटिश शासन के खिलाफ भारतीय लोगों की एकजुटता और स्वतंत्रता की मांग को समर्थन देना था। भारतीय कांग्रेस के संस्थापक में आदरणीय शेषनाथ शास्त्री, डदाभाई नौरोजी, डिनबंदु मित्र, अलेक्जेंडर कानिंघम, विश्वनाथ शास्त्री, नारायण राव और अब्दुल लतीफ शाह शामिल थे।
</p>
	</div>
	<div><p></p></div>
<div className="card3">
	<p classname="mainContent mx-auto">भारतीय कांग्रेस की स्थापना 1885 में हुई थी क्योंकि उस समय भारत में ब्रिटिश शासन के खिलाफ एक मजबूत आंदोलन की आवश्यकता थी। इसके पीछे कुछ मुख्य कारण थे:•	सामाजिक और आर्थिक असमानता: ब्रिटिश शासन के कारण भारतीय समाज में आर्थिक और सामाजिक असमानता बढ़ गई थी। यह असमानता लोगों के बीच आंदोलन की भूमि बनी।
•	स्वतंत्रता की मांग: ब्रिटिश शासन के खिलाफ भारतीयों की स्वतंत्रता की मांगें बढ़ गई थीं। इसलिए, एक संगठित प्लेटफ़ॉर्म की आवश्यकता थी जो इस मांग को समर्थन दे सके।
•	राजनीतिक संघर्ष: विभाजन और विलयन के कारण भारतीय समाज में राजनीतिक संघर्ष था। इस संघर्ष को संघर्ष में लाने के लिए, एक संगठन की आवश्यकता थी जो विभिन्न समूहों को एक समान स्थिति में ले आ सके।
</p>
	
	</div>
	<div><p></p></div>
<div className="card">
	<p classname="mainContent mx-auto">1885 से 1900 तक के दौरान भारतीय राजनीति में कई महत्वपूर्ण घटनाएं और परिवर्तन हुए। यह अवधि भारतीय राष्ट्रीय कांग्रेस की स्थापना के बाद की गई और भारतीय स्वतंत्रता संग्राम के प्रारंभिक दिनों को शामिल करती है।
•	भारतीय राष्ट्रीय कांग्रेस की स्थापना (1885): भारतीय राष्ट्रीय कांग्रेस की स्थापना 1885 में हुई थी, जो भारतीय स्वतंत्रता संग्राम का एक महत्वपूर्ण स्थापना था। इसका मुख्य उद्देश्य था भारतीयों की मांगों को लेकर एक संगठन बनाना और उनके अधिकारों की रक्षा करना।
•	दादाभाई नौरोजी की अध्यक्षता: 1885 में भारतीय राष्ट्रीय कांग्रेस के प्रथम सत्र में दादाभाई नौरोजी को अध्यक्ष चुना गया था।
•	मोड़ीली खिलाफत आंदोलन: 1885 में भारतीय मुसलमानों के बीच मोड़ीली के विरुद्ध एक आंदोलन हुआ, जो उनके संघर्ष को सामाजिक और राजनीतिक मंच पर लाया।
•	देवांगनवेला घटना: 1897 में देवांगनवेला में इंग्लैंडी कारखानेदारों के खिलाफ एक हिंसक आंदोलन हुआ, जिसमें कई भारतीय कार्यकर्ता मारे गए। यह घटना भारतीय स्वतंत्रता संग्राम में एक बड़ी उथल-पुथल का कारण बनी।
•	फरमान-ए-कांग्रेस: 1900 में भारतीय राष्ट्रीय कांग्रेस ने अपनी पहली अधिवेशन में फरमान-ए-कांग्रेस का प्रस्ताव पारित किया, जिसमें वह अपनी मांगों का सूचीकरण करती थी।
</p>
	</div>
	<div><p></p></div>
<div className="card3">
	<p classname="mainContent mx-auto">१९०० से १९४७ तक की भारतीय राजनीति :</p>
	<p>1900 से 1947 तक के दौरान भारतीय राजनीति में कई महत्वपूर्ण घटनाएं और परिवर्तन हुए। इस अवधि में भारतीय स्वतंत्रता संग्राम के प्रमुख चरण शामिल हैं, जिसने भारतीय स्वतंत्रता की प्राप्ति में महत्वपूर्ण भूमिका निभाई। यहाँ कुछ महत्वपूर्ण घटनाओं का संक्षेप दिया गया है:
•	भारतीय राष्ट्रीय कांग्रेस की मजबूती: भारतीय राष्ट्रीय कांग्रेस ने अपनी दक्षिणी और उत्तरी प्रांतों में आधिकारिक रूप से स्थिति मजबूत की। उसने भारतीय स्वतंत्रता के लिए एक महत्वपूर्ण आधार बनाया और स्वतंत्रता संग्राम के नेतृत्व में भूमिका निभाई।
•	जलियांवाला बाग मास्साकर: 1919 में अमृतसर में ब्रिटिश सेना ने जलियांवाला बाग में अमनवादी आंदोलन के दौरान बेजान लोगों पर अत्याचार किया, जिससे बहुत सी मासूमों की मौत हो गई। इस घटना ने ब्रिटिश शासन के खिलाफ भारत में भावनात्मक और सामाजिक दहलीज को बढ़ा दिया।
•	स्वतंत्रता संग्राम: 20वीं सदी के दौरान भारत में विभिन्न स्वतंत्रता संग्रामों की शुरुआत हुई, जिनमें सबसे महत्वपूर्ण थे गांधीजी के आंदोलन, जैसे कि नमक सत्याग्रह, असहिष्णुता के खिलाफ आंदोलन, और भारत छोड़ो आंदोलन।
•	संविधान निर्माण: स्वतंत्रता के बाद, भारतीय संविधान का निर्माण हुआ, जिसमें देश के नए नियम और शासन प्रणाली की व्यवस्था की गई।
•	पार्टीयों की स्थापना: स्वतंत्रता के बाद भारतीय राजनीतिक पार्टियों की स्थापना हुई, जैसे कि कांग्रेस, भारतीय जनता पार्टी, भारतीय जनसंघ, और अन्य।
</p>
	</div>
	<div><p></p></div>
</div>
	
	




</div>
);
};

export default Page6