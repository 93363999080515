import React, {memo} from 'react'

function AuthorCard({author}) {
  return (
    <div className="cardProfile">
     
      
      <p>POSTS : {author.numPosts}</p>
      <p>LIKES : {author.numLikes}</p>
    </div>
  )
}

export default memo(AuthorCard)
