//import useState hook to create menu collapse state
import React, { useState } from "react";
import {Link} from "react-router-dom";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";



//import icons from react icons
import { FaList,  FaRegHeart, FaPagelines, FaBrain, FaFlask, FaLandmark } from "react-icons/fa";
import { FiHome, FiGlobe, FiBookOpen, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";


//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";


const Header = () => {
  
    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(true)
   

    //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);

     };

   
  return (
    
    <>
      <div id="header" onMouseEnter={() => setMenuCollapse(false) }
        onMouseLeave={() => setMenuCollapse(true)} style={{ display: 'flex'  }}>
          {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}  >
          <SidebarHeader onMouseEnter={() => setMenuCollapse(false)}
        onMouseLeave={() => setMenuCollapse(true)} >
          <div className="logotext">
              {/* small and big change using menucollapse state */}
              <p>{menuCollapse ? "M" : "Mahakalyan"}</p>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
                {/* changing menu collapse icon on click */}
              {menuCollapse ? (
                <FiArrowRightCircle/>
              ) : (
                <FiArrowLeftCircle/>
              )}
            </div>
            
          </SidebarHeader >
          <SidebarContent onMouseEnter={() => setMenuCollapse(false)}
        onMouseLeave={() => setMenuCollapse(true)}>
            <Menu iconShape="square">
            
              <MenuItem active={true} icon={<FiGlobe />}>
              <Link to="/" class="link"> अंतरराष्ट्रीय विषय </Link>
              </MenuItem>
              <MenuItem icon={<FiBookOpen />}>
              <Link to="/page2" class="link"> निशुल्क शिक्षा </Link></MenuItem>
              <MenuItem icon={<FaPagelines />}>
              <Link to="/page3" class="link"> पर्यावरण संरक्षण</Link></MenuItem>
              <MenuItem icon={<FaBrain />}>
              <Link to="/page4" class="link"> प्रेरणादायक विचार</Link></MenuItem>
              <MenuItem icon={<FaFlask />}>
              <Link to="/page5" class="link"> विज्ञान एवं प्रोद्योगिकी</Link></MenuItem>
              <MenuItem icon={<FaLandmark />}>
              <Link to="/page6" class="link"> राजनीति</Link></MenuItem>
            </Menu>
          </SidebarContent>  
          <SidebarFooter onMouseEnter={() => setMenuCollapse(false)}
        onMouseLeave={() => setMenuCollapse(true)}>
            {/*<Menu iconShape="square">
              <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
              </Menu> */}
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default Header;