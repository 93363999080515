import React from "react";
import './Page5.css'

export const Page6 = () => {
return (

	<div class="shi1" >
    
	  <div className=" p-5 ">
	<div className="cardProfile">
	<h1 classname="title">विज्ञान एवं प्रौद्योगिकी </h1>
	</div>
<div><p></p></div>

<div className="card3">
	<p classname="mainContent mx-auto">विज्ञान एवं प्रौद्योगिकी: मानवता की प्रगति का आधार</p>
	<p>विज्ञान एवं प्रौद्योगिकी, मानव सभ्यता के दो स्तंभ हैं, जो सदैव से ही मानवता की प्रगति का आधार रहे हैं। विज्ञान, प्रकृति और ब्रह्मांड के रहस्यों को समझने का प्रयास है, जबकि प्रौद्योगिकी, वैज्ञानिक ज्ञान का व्यवहारिक उपयोग है। इन दोनों के समन्वय से ही मानव जीवन में क्रांतिकारी बदलाव आते हैं।


</p>
	</div>
	<div><p></p></div>


	<div className="card">
	<p classname="mainContent mx-auto">विज्ञान के लाभ:</p>
	<p>•	ज्ञान में वृद्धि: विज्ञान हमें प्रकृति और ब्रह्मांड के बारे में सटीक जानकारी प्रदान करता है, जिससे हमारी समझ और ज्ञान में वृद्धि होती है।
•	समस्याओं का समाधान: विज्ञान विभिन्न क्षेत्रों में उत्पन्न होने वाली समस्याओं का वैज्ञानिक ढंग से समाधान ढूंढने में मदद करता है।
•	नए आविष्कार: विज्ञान के माध्यम से नए-नए आविष्कार होते हैं, जो मानव जीवन को बेहतर बनाने में महत्वपूर्ण भूमिका निभाते हैं।

</p>
	</div>
	<div><p></p></div>

	<div className="card3">
	<p classname="mainContent mx-auto">प्रौद्योगिकी के लाभ:</p>
	<p>•	जीवन स्तर में सुधार: प्रौद्योगिकी जीवन के हर क्षेत्र में क्रांति ला रही है, जिससे जीवन स्तर में सुधार हो रहा है।
•	रोजगार के अवसर: प्रौद्योगिकी के क्षेत्र में नए-नए रोजगार के अवसर पैदा हो रहे हैं।
•	वैश्वीकरण: प्रौद्योगिकी ने दुनिया को एक छोटा गांव बना दिया है, जिससे वैश्वीकरण को गति मिली है।

•	भौतिक विज्ञान (Physics): भौतिक विज्ञान उसके विभिन्न रूपों, ऊर्जा और गतिविधियों का अध्ययन करता है। यह गतिविधि, ऊर्जा, और मादा के संरचना के लिए सिद्धांतों को अध्ययन करता है।
•	रसायन विज्ञान (Chemistry): रसायन विज्ञान मादा की संरचना, गुणधर्म, और उसकी व्यवहारिकता का अध्ययन करता है। यह रसायनों के संयोजन, अविकल, और उपयोग के बारे में भी शोध करता है।
•	जीव विज्ञान (Biology): जीव विज्ञान जीवन की संरचना, कार्य, और प्रक्रियाओं का अध्ययन करता है। इसमें जीवित प्राणियों, पौधों, और माइक्रोऑर्गेनिज्मों का अध्ययन शामिल होता है।
•	गणित (Mathematics): गणित संख्यात्मक और तार्किक विचार का अध्ययन करता है। यह रीखा, आंकड़ा, गुणित, और अन्य गणितीय नियमों का अध्ययन करता है।
•	पृथ्वी विज्ञान (Earth Science): पृथ्वी विज्ञान पृथ्वी, उसके परत, और उसके आसपास के घटकों का अध्ययन करता है। इसमें भूकंप, जलवायु, और प्राकृतिक परिवर्तन शामिल होते हैं।
•	वनस्पति विज्ञान (Botany): वनस्पति विज्ञान पौधों, पेड़ों, और अन्य वनस्पतियों का अध्ययन करता है। यह उनकी संरचना, उनकी संवेदनशीलता, और उनके जीवन की प्रक्रियाओं का अध्ययन करता है।
•	आदिम विज्ञान (Astronomy): आदिम विज्ञान



</p>
	</div>
	
	<div><p></p></div>

	<div className="card">
	<p classname="mainContent mx-auto">विज्ञान और प्रौद्योगिकी के प्रभाव</p>
	<p>•	सामाजिक परिवर्तन: विज्ञान और प्रौद्योगिकी ने समाज में क्रांति लाई है, जिससे संबंधों, संगठन और सामाजिक व्यवहारों में सुधार हुआ है।
•	आर्थिक विकास: तकनीकी उन्नति ने उत्पादन, बाजार, और नौकरियों में वृद्धि की है, जिससे आर्थिक विकास हुआ है।
•	व्यक्तिगत सुधार: विज्ञान और प्रौद्योगिकी ने आदमी के जीवन को सुविधाजनक बनाने के लिए कई नई और उत्तम तकनीकी साधन प्रदान किए हैं।
•	सांस्कृतिक परिवर्तन: नई तकनीकों के प्रयोग से सांस्कृतिक विभिन्नता और समरसता में सुधार हुआ है।
•	शिक्षा का बदलाव: शिक्षा के क्षेत्र में नई तकनीकों का प्रयोग होने से पाठ्यक्रम, शिक्षा पद्धतियाँ और शिक्षकों के काम में बदलाव हुआ है।
•	रोजगार का स्थितिगतीकरण: ऑटोमेशन और अन्य तकनीकी उपायों से कई रोजगार के क्षेत्रों में स्थितिगतीकरण हो रहा है।
•	स्वास्थ्य और चिकित्सा: वैज्ञानिक अनुसंधान और नवाचारों के कारण चिकित्सा और स्वास्थ्य सेवाओं में सुधार हुआ है।
•	पर्यावरण में प्रभाव: वैज्ञानिक उपकरणों और तकनीकों का उपयोग पर्यावरण के प्रति असर डालता है, जैसे कि जल प्रदूषण, वायु प्रदूषण, आदि।
•	संचार की अधिकता: विज्ञान और प्रौद्योगिकी के उत्थान से संचार की अधिकता बढ़ी है, जिससे लोग आपस में अधिक जुड़े हैं।
•	सुरक्षा और निजता: तकनीकी उपायों के प्रयोग से व्यक्तिगत सुरक्षा और निजता की संरक्षा में सुधार हुआ है।



</p>
	</div>
	<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">विज्ञान एवं प्रौद्योगिकी के कुछ महत्वपूर्ण क्षेत्र:</p>
<p>•	अंतरिक्ष विज्ञान: अंतरिक्ष विज्ञान, ब्रह्मांड के रहस्यों को समझने का प्रयास करता है।
•	चिकित्सा विज्ञान: चिकित्सा विज्ञान, मानव स्वास्थ्य और रोगों से बचाव का अध्ययन करता है।
•	कृषि विज्ञान: कृषि विज्ञान, कृषि उत्पादन और खाद्य सुरक्षा को बेहतर बनाने का अध्ययन करता है।
•	सूचना एवं संचार प्रौद्योगिकी: सूचना एवं संचार प्रौद्योगिकी, सूचना के आदान-प्रदान और संचार के साधनों का अध्ययन करता है।



</p>
</div>
<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">विज्ञान एवं प्रौद्योगिकी के क्षेत्र में भारत की उपलब्धियां:</p>
<p>•	भारतीय अंतरिक्ष अनुसंधान संगठन (ISRO): ISRO ने अंतरिक्ष विज्ञान के क्षेत्र में कई महत्वपूर्ण उपलब्धियां हासिल की हैं।
•	भारतीय चिकित्सा अनुसंधान परिषद (ICMR): ICMR ने चिकित्सा विज्ञान के क्षेत्र में कई महत्वपूर्ण खोजें की हैं।
•	भारतीय कृषि अनुसंधान परिषद (ICAR): ICAR ने कृषि विज्ञान के क्षेत्र में कई महत्वपूर्ण योगदान दिए हैं।
•	टाटा कंसल्टेंसी सर्विसेज (TCS): TCS, सूचना एवं संचार प्रौद्योगिकी के क्षेत्र में एक प्रमुख कंपनी है।


</p>
</div>
<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">
विज्ञान और प्रौद्योगिकी के नवाचार:
</p>
<p>•	आर्टिफिशियल इंटेलिजेंस (AI): AI ने नए संभावनाओं का दरवाजा खोला है, जो मानवता के लिए संवेदनशील और सुरक्षित तकनीकों को उत्पन्न कर सकता है।
•	नैनोटेक्नोलॉजी: नैनोटेक्नोलॉजी ने छोटे स्तर पर नवीनतम और अद्वितीय तकनीकों का अध्ययन किया है, जिससे उत्पादों की क्षमता बढ़ी है और उनका उपयोग व्यापक हो गया है।
•	जीन एडिटिंग (Gene Editing): जीन एडिटिंग ने जीवन विज्ञान के क्षेत्र में क्रांति ला दी है, जिससे जीवों के जीनों में संशोधन करना संभव हो गया है।
•	बायोमेट्रिक्स: बायोमेट्रिक्स तकनीक ने व्यक्तिगत सुरक्षा, डेटा अद्यतन, और पहचान प्रणालियों में क्रांति लाई है।
•	क्वांटम कंप्यूटिंग: क्वांटम कंप्यूटिंग ने गणितीय और संज्ञानात्मक क्षेत्रों में अद्वितीय गति को संभव बनाया है।
•	इंटरनेट ऑफ थिंग्स (IoT): IoT ने संगठनों को उत्पादकता, सुरक्षा, और संचार में सुधार करने की क्षमता प्रदान की है।
•	3D प्रिंटिंग: 3D प्रिंटिंग ने नई उत्पादों के निर्माण में सरलता और अद्वितीयता लाई है।
•	वायरल जीनेटिक्स: वायरल जीनेटिक्स ने नई और प्रभावी औरोंत के उत्पादन की ओर एक महत्वपूर्ण कदम बढ़ाया है।
•	ब्लॉकचेन तकनीक: ब्लॉकचेन ने डिजिटल लेजर के रूप में गहनता कायम की है और संचार को अधिक सुरक्षित और निजी बनाया है।
•	रोबोटिक्स: रोबोटिक्स ने विभिन्न क्षेत्रों में स्वचालित और उत्पादक तकनीकों का विकास किया है, जिससे कार्यों को सुगम बनाया जा सकता है।



</p>
</div>
<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">
विज्ञान एवं प्रौद्योगिकी के क्षेत्र में भारत के सामने चुनौतियां:
</p>
<p>•	अनुसंधान एवं विकास में निवेश की कमी: भारत को अनुसंधान एवं विकास में अधिक निवेश करने की आवश्यकता है।
•	वैज्ञानिकों और इंजीनियरों की कमी: भारत में वैज्ञानिकों और इंजीनियरों के लिए पर्याप्त अवसर नहीं हैं, जिसके कारण वे विदेशों में बेहतर अवसरों की तलाश में जाते हैं। 
•	नैतिकता: विज्ञान एवं प्रौद्योगिकी के विकास के साथ-साथ नैतिकता का भी ध्यान रखना आवश्यक है, ताकि इनका दुरुपयोग न हो।
•	डिजिटल डिवाइड: डिजिटल डिवाइड, समाज में असमानता को बढ़ा सकता है।
•	पर्यावरणीय प्रभाव: प्रौद्योगिकी के विकास का पर्यावरण पर नकारात्मक प्रभाव पड़ सकता है।



</p>
</div>
	</div>


</div>
);
};

export default Page6