import React, {memo} from 'react'
import './Footer.css'

function Footer() {
  return (
    <div class="cc22">
      
    
     © 2023 Mahakalyan. All rights reserved.

    </div>
  )
}
export default memo(Footer)
