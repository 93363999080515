import React from "react";
import './Page4.css'

export const Page6 = () => {
return (

	<div class="shi1" >
    
	  <div className=" p-5 ">
	<div className="cardProfile">
	<h1 classname="title">प्रेरणा दायक विचार: </h1>
	</div>
<div><p></p></div>


<div className="card3">
	<p classname="mainContent mx-auto">राम मनोहर लोहिया जी के प्रेरणादायक विचार:</p>
	<p>•	जो व्यक्ति संघर्ष की चरम सीमा तक पहुंचता है, वह जीवन की सार्थकता को समझता है।

•	स्वतंत्रता का मतलब है स्वाधीनता, और स्वाधीनता का मतलब है अपनी अदालती सामर्थ्य की प्राप्ति करना।

•	समाज में न्याय की सबसे अधिक आवश्यकता है, क्योंकि न्याय ही समाज की आत्मा है।

•	व्यक्ति का सबसे बड़ा शत्रु उसकी आत्म है, जो उसे उसके आत्म-विश्वास से दूर कर देती है।

•	सत्य, स्वाधीनता, और न्याय का आदर्श अपनाने का समय आ गया है, क्योंकि यही उन सामाजिक बुराइयों का समाधान है जो हमारे समाज को विभाजित करती हैं।


</p>
	</div>
	<div><p></p></div>


	<div className="card">
	<p classname="mainContent mx-auto">अटल बिहारी वाजपेयी जी के प्रेरणादायक विचार:</p>
	<p>•	सपने वो नहीं होते जो हम सोते समय देखते हैं, सपने वो होते हैं जो हमें सोने नहीं देते।

•	शक्ति और साहस के बिना कोई देश अपनी महानता की ऊँचाइयों को छू नहीं सकता।

•	सत्य पर चलने वाले हमेशा से अकेले होते हैं।

•	विश्वास, संघर्ष, और सफलता की कुंजी है।

•	समाज की सबसे बड़ी आवश्यकता है की हमें अपने देश के लिए अधिक और अधिक कार्य करने की प्रेरणा मिले।

•	सभी के मन में एक सपना होता है, लेकिन कुछ लोग ही वह सपना जगाते हैं।

•	राजनीति एक कला है, और शक्ति का उपयोग सर्वश्रेष्ठ कला है।

</p>
	</div>
	<div><p></p></div>

	<div className="card3">
	<p classname="mainContent mx-auto">एपीजे अब्दुल कलाम जी के प्रेरणादायक विचार :</p>
	<p>•	अगर आप सपने देखना चाहते हैं तो आपको उन्हें साकार करने के लिए काम करना होगा।

•	सफलता उन्हीं के पास होती है जो कुछ न कुछ करते रहते हैं।

•	शिक्षा का उद्देश्य चरित्र के विकास में होना चाहिए।

•	अगर आप अपने लक्ष्य के पीछे नहीं भागते, तो आप उन्हें कभी भी प्राप्त नहीं कर सकते।

•	सपने वहां नहीं होते जहां सड़क होती है, सपने वहां होते हैं जहां रास्ता होता है।

•	विज्ञान के द्वारा हम समाज को सुधार सकते हैं, लेकिन धर्म के द्वारा हम अपने आत्मा को सुधार सकते हैं।

•	अपने सपनों को पाने के लिए आपको उन्हें साकार करने के लिए काम करना होगा, और उन पर विश्वास करना होगा।


</p>
	</div>
	
	<div><p></p></div>

	<div className="card">
	<p classname="mainContent mx-auto">भीमराव अंबेडकर जी  के  प्रेरणादायक विचार :</p>
	<p>•	अगर आप शिक्षा प्राप्त करते हैं, तो आप सभी कुछ कर सकते हैं।

•	समाज का सबसे बड़ा शत्रु उसकी जाति प्रथा है।

•	समाज में न्याय की सबसे बड़ी आवश्यकता है, क्योंकि न्याय ही समाज की आत्मा है।

•	जीवन में सफलता के लिए आपको शिक्षा, संघर्ष और समर्पण की आवश्यकता होती है।

•	समाज को उसकी अस्तित्व की जरूरत होती है, न कि उसकी परंपराओं की।

•	धर्म की सबसे बड़ी बाधा है मानवता से दूरी।

•	प्रेम एकमात्र आवश्यकता है।




</p>
	</div>
	<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">स्वामी विवेकानन्द  जी  के  प्रेरणादायक विचार :</p>
<p>•	उठो, जागो, और अपने महान स्वरूप को पहचानो।
•	वही जीत होती है, जो हारने का डर नहीं रखता।
•	हमें अपने आदर्शों के लिए मरने का वचन देना चाहिए, न कि उन्हें मरने के लिए।
•	धर्म अपने स्वार्थ के लिए नहीं, अपने उपकार के लिए होता है।
•	सभी धर्मों का आदर्श है सहानुभूति।
•	आत्मा महान है, उसको अज्ञान से ढ़केलकर मत रखो।
•	जो व्यक्ति अपने लक्ष्य की ओर सीधे नहीं बढ़ता, वह दुसरों की आलोचना करता है।



</p>
</div>
<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto">गौतम बुद्ध   जी  के  प्रेरणादायक विचार :</p>
<p>•	तुम वही हो जो तुम सोचते हो, तुम वही हो जो तुम अपने विचारों में बनाते हो, और तुम वही हो जो तुम अपने कर्मों में बनाते हो।
•	जीवन दुःख हैं। इसे संजीवनी बनाने का रास्ता है संयम।
•	उसे ही धर्मी मानो, जो दूसरों के दुःख को कम करता है।
•	सभी प्राणियों पर दया करो, बड़े और छोटे सभी पर दया करो।
•	अपने धर्म को धारण करने के लिए शान्ति का पालन करो।
•	सत्य ही धर्म है, धर्म ही सत्य है।
•	जीते जी अपने आत्मा के अनुसार जियो।




</p>
</div>


<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">सुकरात जी  के  प्रेरणादायक विचार :</p>
<p>•	जो भी मैं जानता हूँ, वही मैं जानता हूँ कि मैं कुछ नहीं जानता हूँ.
•	अनेक विचारक जीवन में जीते हैं, लेकिन शुद्ध विचारक जीवन को अनुभवते हैं।
•	अधिकतम सुख यह है कि तुम्हें अपनी भूलों का अनुभव हो।
•	बुद्धिमान वही होता है जो अपने अज्ञान को स्वीकार करता है।
•	अनैतिकता अज्ञान का परिणाम है।
•	ज्ञान से अधिक महत्वपूर्ण होता है केवल यही कि तुम क्या सोचते हो और उसे कैसे सोचते हो।
•	सबसे अच्छा योग्यता वह होती है जो अच्छा विचारक बनाती है।





</p>
</div>

<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto">मेगास्थेनीज़ जी  के  प्रेरणादायक विचार :</p>
<p>•	जीवन में सफलता के लिए समझदारी और विवेक बहुत जरूरी है।
•	विचार की शक्ति ही व्यक्ति को सबसे ऊंचे पद तक पहुंचा सकती है।
•	सभी दुर्बलता का कारण अज्ञान है, और सभी सामर्थ्य का कारण ज्ञान है।
•	समय और समय की परिकल्पना नहीं की गई धरातल पर जितना समय लगाया जाता है, वह अपनी आशीर्वाद बहुत अधिक अनुमानित किया जाता है।
•	सत्य का अनुसरण करें, क्योंकि यही व्यक्ति को सच्ची ज्ञान की ओर ले जाता है।
•	समाज में न्याय के लिए सभी को जिम्मेदारी लेनी चाहिए।
•	बुद्धिमान व्यक्ति हमेशा अपने विचारों को सही मायने में ज़रा से गलत भाषा में नहीं कहेंगे।






</p>
</div>
<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">आचार्य चाणक्य जी के प्रेरणादायक विचार:</p>
<p>शिक्षा ही एक ऐसा धन है जो चोरी नहीं हो सकता।
जो काल कर्म को नष्ट कर देता है, उसी काल से मनुष्य को सावधान रहना चाहिए।
जो मनुष्य दूसरों के दुःखों को देखकर दुखी नहीं होता, वह मनुष्य नहीं पशु है।
सत्य बोलो, दूसरों को क्षमा करो, दान करो, और सदाचार का पालन करो।
क्रोध, लोभ, मोह, मद और मत्सर ये पांच मनुष्य के शत्रु हैं।







</p>
</div>
<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto">मदर टेरेसा जी के  प्रेरणादायक विचार:</p>
<p>•	बड़ा कुछ करने का हममें शक्ति नहीं है, लेकिन हम वहाँ पर प्यार के साथ छोटे काम कर सकते हैं।
•	जहाँ भी जाओ, प्यार फैलाओ। किसी को खुश छोड़ने के बिना कभी भी किसी के पास न आने दो।
•	मैं अकेला दुनिया को नहीं बदल सकता, लेकिन मैं एक छोटी सी लहर उत्पन्न कर सकता हूं जो बड़े परिवर्तन में सहायक हो सकती है।
•	हमें खुद महसूस होता है कि हमारा कार्य समुद्र में एक बूँद ही है, लेकिन वह समुद्र बिना उस बूंद के नहीं हो सकता।
•	अगर तुम लोगों का निर्णय करते रहोगे, तो तुम्हें उन्हें प्यार करने का समय ही नहीं मिलेगा।






</p>
</div>
<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">नेल्सन मंडेला जी के  प्रेरणादायक विचार:</p>
<p>•	जब लोगों के साथ साझा किया जाता है, तो शक्ति बढ़ती है, न कि कम होती है।
•	हमें सभी को एक अलग और न्यायान्वेषी दृष्टिकोण के साथ देखना चाहिए।
•	उन्हें हार कहानियाँ सुनाओ, लेकिन हमेशा जीत के बारे में सोचो।
•	शिक्षा शक्ति है, और मैं उसी शक्ति का आशीर्वाद देता हूँ।
•	विश्वास, निश्चितता और संघर्ष वाले लोग ही समस्याओं को हल कर सकते हैं।







</p>
</div>
<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto"> लेओनार्डो दा विंची जी के  प्रेरणादायक विचार:</p>
<p>•	अच्छी प्रार्थना ही वह बड़ी शक्ति है जो हमें अपने आप को बुराई से दूर रखती हैं।
•	विवेक एक खिलाफत है जो अकेले खुशी नहीं देता।
•	जीने का एक साहस है।
•	जिसके पास अपनी चीजों का स्वामित्व होता है, वही जीवन में सच्चे स्वतंत्र होता है।
•	जो जानता है, वह अधिक से अधिक जानता है कि वह कुछ नहीं जानता है।







</p>
</div>

<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto"> सी.एस. लुईस (C.S. Lewis) जी के  प्रेरणादायक विचार:</p>
<p>•	सच्चे और सजीव विश्वास इसीलिए महत्वपूर्ण हैं, क्योंकि वे सिर्फ हमारी सोच को बदलते हैं, बल्कि हमारे जीवन को बदलते हैं।
•	वास्तविक शिक्षा वह है जो तुम्हें सोचने के लिए मजबूर करे, न कि जो तुम्हें विचारों की निगरानी के लिए मजबूर करे।
•	हर एक परेशानी में, हर एक चुनौती में, हमें एक नई अवस्था की तलाश करनी चाहिए।
•	विवेकी मनुष्य अपनी वास्तविकता को पहचानता है और अपनी उत्तरदायित्व को स्वीकार करता है।
•	सफलता का रहस्य है, अच्छा समय बनाने की क्षमता।








</p>
</div>

<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto"> मैरी क्यूरी जी के  प्रेरणादायक विचार:</p>
<p>•	"जीवन की सबसे महत्वपूर्ण चीज न तो हमारे जीते जाने के तरीके होते हैं, बल्कि हमारे कार्य होते हैं।"
•	"संघर्ष के समय में ताकत और सहनशीलता से ही हमारे सपने पूरे हो सकते हैं।"
•	"जब आप किसी को अपने सपनों के बारे में बताते हैं, तो आप उसे अपनी सोच की ऊँचाइयों का अनुभव करवाते हैं।"
•	"जब कोई आपके सपनों को नकारता है, तो यह उनकी बात होती है, न कि आपकी।"
•	"सफलता का रहस्य इसमें है कि आप अपने सपनों की ताकत और संघर्ष को समझते हैं, और हार नहीं मानते।"
•	दलाई लामा जी के  प्रेरणादायक विचार:-
•	
•	"विश्वास का आधार प्यार है, और प्यार का आधार सच्चाई है।"
•	"सकारात्मक विचार मनुष्य को शक्ति देते हैं।"
•	"बदलाव केवल व्यक्तिगत स्तर पर नहीं, बल्कि समाज के स्तर पर भी होना चाहिए।"
•	"हमें हर व्यक्ति को उनके मौलिक अधिकार और स्वतंत्रता के लिए लड़ना चाहिए।"
•	"संतोष केवल बाहरी घटनाओं पर निर्भर नहीं होता, बल्कि यह हमारे आंतरिक स्थिति का परिणाम होता है।"









</p>
</div>

<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto"> स्टीफन हॉकिंग जी के  प्रेरणादायक विचार:-</p>
<p>•	"आपके किसी भी बाधाओं के बावजूद, आप जो चीज़ नहीं कर सकते हैं, वह जो चीज़ कर सकते हैं, वह आपको अच्छे से कर सकती है।"
•	"अगर आप अपने जीवन की लक्ष्य साधना के लिए बहुत ही सक्षम नहीं हैं, तो कम से कम अपने जीवन के लक्ष्यों की ताकतवर रूप से कोशिश करें।"
•	"मैं समझता हूँ कि अगर आप अध्ययन करते हैं और अपने सोच को स्थिर रखते हैं, तो आप अपनी कोई भी समस्या को हल कर सकते हैं।"
•	"संभवतः बाहरी प्रेरणा के बजाय, मैंने मेरे अंतर्निहित प्रेरणास्रोतों को ज्यादा महत्व दिया है।"
•	"हमेशा एक लक्ष्य रखें, जैसा कि मैंने किया था। जीत के लिए आगे बढ़ें और कभी हारने के लिए ना डरें।"









</p>
</div>

<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto"> विलियम शेक्सपियर जी के  प्रेरणादायक विचार:-</p>
<p>•	"शिक्षा एक धन है जो चोरी होते हुए नहीं होता।"
•	"जितनी भी शक्ति हो, उतनी ही जिम्मेदारी होती है।"
•	"हमें अपने स्वप्न नहीं, बल्कि उन्हें साकार करने की क्षमता होनी चाहिए।"
•	"धर्म की प्रवृत्ति किसी भी रूप में बदल जाना चाहिए।"
•	"हर कोई अपनी कहानी की अद्वितीयता के बारे में बात करता है।"









</p>
</div>

<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto"> अल्बर्ट आइंस्टीन जी के  प्रेरणादायक विचार:-</p>
<p>•	"जो समस्या हमें उत्तर नहीं दे सकती, उसको बदलना चाहिए, न कि हमें उसमें उलझना चाहिए।"
•	"जब ज्ञान बढ़ जाता है, तो आदमी की सीमाओं का अनुभव बढ़ता है।"
•	"शिक्षा का लक्ष्य नये विचारों की प्रेरणा देना होता है, न कि आत्मसमर्थन कराना।"
•	"समस्या का समाधान वही है जो हमें परिणामों के सोचने की क्षमता प्रदान करता है, जिससे कि हम समस्या को एक नए स्तर पर समझ सकें।"
•	"बुद्धिमान लोग उन लोगों की अप्रत्याशित बातों को स्वीकार करते हैं, जो अन्य लोगों को मूर्ख लगती हैं।"









</p>
</div>

<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto"> एलोन मस्क जी के  प्रेरणादायक विचार:-</p>
<p>•	"हमें लगता है कि यदि कुछ तात्कालिक संघर्षपूर्ण होता है, तो यह संघर्षशील होना है।"
•	"आप जो भी करते हैं, यह अवश्य विश्वास रखने की कोशिश करें कि आपका काम महत्वपूर्ण है। एक महान काम करने की सीमा सिर्फ विश्वास है।"
•	"उद्योग का उद्घाटन करना आसान नहीं होता है, लेकिन जब आप एक बार शुरू कर देते हैं, तो आपको कोई भी चीज़ आवश्यक नहीं होती है।"
•	"मुझे लगता है कि आप वास्तव में किसी को प्रेरित नहीं कर सकते हैं, वास्तविकता यह है कि वे स्वयं को प्रेरित करें।"
•	"आप चाहे जितने भी अच्छे हों, लेकिन अगर आप विश्वास नहीं करते हैं कि आप यह कर सकते हैं, तो आप यह नहीं कर सकते।"








</p>
</div>

<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto"> स्टीव जॉब्स जी के  प्रेरणादायक विचार:-</p>
<p>•	"अगर आप कुछ नहीं मिलता है तो अपने आप को नहीं, बल्कि विश्वास में समस्या होती है।"
•	"अगर आप कुछ बदलना चाहते हैं, तो वह संभव है कि आपको सबसे पहले अपने आप को बदलना पड़ेगा।"
•	"आप वास्तव में कुछ महत्वपूर्ण करना चाहते हैं तो आपको अपने मन से प्यार करना चाहिए।"
•	"आप अपने काम में अपनी प्रेम से कुछ महत्वपूर्ण कर सकते हैं।"
•	"जो भी कार्य करते हैं, वे अपने दिल से करें। आपको अपने काम में प्यार होना चाहिए।"









</p>
</div>


<div><p></p></div>

<div className="card">
<p classname="mainContent mx-auto"> •	लियो टॉलस्टॉय जी के  प्रेरणादायक विचार:-</p>
<p>•	"सच्ची खुशी उस व्यक्ति के अंदर होती है, जो दूसरों की मदद करता है।"
•	"सबसे बड़ा विज्ञान और धर्म है, अपने को और अपने काम को समझना।"
•	"आपका जीवन केवल आपके विचारों के हिसाब से होता है।"
•	"संघर्ष का सही मतलब यह है कि आप कितनी बार गिरते हैं, बल्कि यह है कि आप कितनी बार उठते हैं।"
•	"आपकी सफलता का रहस्य यही है कि आप उस समय तक नहीं हारते, जब तक आप यह नहीं मान लेते कि आप अच्छे कार्य करने में सक्षम हैं।"










</p>
</div>


<div><p></p></div>


	</div>


</div>
);
};

export default Page6