import React, {useState, useEffect, useCallback} from 'react'
import './PostPage.css'
import db from '../../utils/db.json'

function PostCard({post}) {
  const [authorName, setauthorName] = useState('')

  const fetchData = useCallback(() => {
    const user = db.authors[post.authorId]

    setauthorName(user.firstName + ' ' + user.lastName)
  }, [post.authorId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  
  return (
    <div class="shi1" >
      <p></p>
      <p></p>
      <div class="card1">
      <h1 >{post.title}</h1></div>
      <div className=" p-5 ">
        <div className="card">{post.description}</div>
        <div className="mainContent mx-auto"><p></p></div>
       {/* <div className="card3">{props.blogText2}</div> */}
        {Object.values(post).map((el,i)=>{
return (post[`blogText${i}`]?<>
<div className="mainContent mx-auto"><p></p></div>
        <div className={`card${i%2==0?'3':''}`}>{post[`blogText${i}`]}</div>
        </>:<></>)
        })}
        

      {/*}  <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText4}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText5}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText6}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText7}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText8}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText9}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText10}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText11}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText12}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText13}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText14}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText15}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card3">{props.blogText16}</div>
        <div className="mainContent mx-auto"><p></p></div>
        <div className="card">{props.blogText17}</div>
        <div className="mainContent mx-auto"><p></p></div>
      <div className="card3">{props.blogText18}</div> */}
        
      </div>
      <div class="card2">
      <p className="title text-secondary">
        Date : {post.datePublished}
      </p>
      <p>विषय  : {authorName}</p>
      <p>LIKES : {post.numLikes}</p></div>
    </div>
  )
}
export default PostCard
