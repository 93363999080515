import React from "react";
import './Page3.css'

export const Page6 = () => {
return (

	<div class="shi1" >
    
	  <div className=" p-5 ">
	<div className="cardProfile">
	<h1 classname="title">भारतीय पर्यावरण: संरक्षण, समस्याएं और समाधान</h1>
	</div>
<div><p></p></div>

<div className="card">
	
	<p>पर्यावरण, भारतीय समाज के लिए अत्यंत महत्वपूर्ण है। यह देश की समृद्धि, स्थिरता और विकास का मूल आधार है। हालांकि, भारतीय पर्यावरण के संरक्षण में आज भी कई चुनौतियाँ हैं। इस लेख में, हम भारतीय पर्यावरण की महत्वपूर्ण बातें, उसकी समस्याओं के कारण और उनके समाधानों पर चर्चा करेंगे।
</p>
	</div>
	<div><p></p></div>



<div className="card3">
	<p classname="mainContent mx-auto">भारतीय पर्यावरण की महत्वपूर्ण बातें:</p>
	<p>•	विविधता की संरक्षण: भारत में विभिन्न जलवायु और भौतिक स्थितियों के कारण अत्यधिक जैव विविधता है। इसे संरक्षित रखना अत्यंत महत्वपूर्ण है।
•	जल संसाधन: भारत में जल संसाधन की महत्वपूर्ण भूमिका है। यह देश की आजीविका और अर्थव्यवस्था के लिए अत्यंत महत्वपूर्ण है।
•	वन्यजीव संरक्षण: भारत में वन्यजीव संरक्षण का महत्वपूर्ण बिंदु है, क्योंकि यह अनेक प्रकार के जीवों को संरक्षित रखता है और जीव आश्रय प्रदान करता है।
•	साफ और स्वच्छ वातावरण: साफ़ और स्वच्छ वातावरण भारतीय स्वास्थ्य और जीवन के लिए अत्यंत महत्वपूर्ण है।


</p>
	</div>
	<div><p></p></div>


	<div className="card">
	<p classname="mainContent mx-auto">भारतीय पर्यावरण की समस्याएँ:</p>
	<p>•	वायु प्रदूषण: भारत में वायु प्रदूषण की समस्या बढ़ रही है, जो सामान्य जनता के स्वास्थ्य को प्रभावित कर रही है।
•	जल प्रदूषण: जल प्रदूषण का स्तर भी बढ़ रहा है, जिसका असर भारत के जल संसाधनों और जलवायु पर दिखाई दे रहा है।
•	वन्यजीव उत्पीड़न: वन्यजीवों के संरक्षण में बाधाएँ बढ़ रही हैं, जैसे कि वन्यजीवों के आवास की खासी घातक निर्माण और उनकी अवैध वाणिज्यिक उत्पादन।



</p>
	</div>
	<div><p></p></div>

	<div className="card3">
	<p classname="mainContent mx-auto">भारतीय पर्यावरण के समाधान:</p>
	<p>•	पर्यावरणीय शिक्षा: जनता को पर्यावरणीय जागरूकता बढ़ाने के लिए शिक्षा का महत्वपूर्ण योगदान होना चाहिए।
•	ऊर्जा के प्रदायन: अधिक आधुनिक और साफ ऊर्जा स्रोतों का उपयोग करना चाहिए, जिससे जल, वायु, और माटी को नुकसान न हो।
•	प्रदूषण नियंत्रण: अधिक प्रभावी प्रदूषण नियंत्रण उपायों का अधिक प्रयास किया जाना चाहिए।
•	संरक्षण क्षेत्रों का संरक्षण: वन्यजीव संरक्षण क्षेत्रों की संरक्षण की आवश्यकता है, जिससे अधिक जीवों को संरक्षित किया जा सके।



</p>
	</div>
	
	<div><p></p></div>

	<div className="card">
	<p classname="mainContent mx-auto">पर्यावरण संरक्षण के लिए भारत में कई महत्वपूर्ण आंदोलन चलाए गए हैं, जो समाज को पर्यावरणीय जागरूकता और संरक्षण के प्रति उत्तेजित किया है। इनमें से कुछ प्रमुख आंदोलन निम्नलिखित हैं:</p>
	<p>•	चिपको आंदोलन: यह आंदोलन उत्तराखंड में 1970 के दशक में आरंभ हुआ, जिसमें लोगों ने पर्यावरणीय संरक्षण के लिए पेड़ों की रक्षा के लिए अपना सामर्थ्य प्रदर्शित किया।

•	नर्मदा बचाओ आंदोलन: यह आंदोलन गुजरात, महाराष्ट्र, और मध्य प्रदेश में नर्मदा नदी पर निर्मित सरकारी बांधों के खिलाफ था, जिसका पर्यावरण और सामाजिक प्रभावों पर सवाल था।
•
•	चीनाई प्लास्टिक प्रदूषण को बचाओ आंदोलन: यह आंदोलन चेन्नई में प्लास्टिक प्रदूषण के खिलाफ था और लोगों को प्लास्टिक के उपयोग में संज्ञाना और बदलाव लाने के लिए प्रेरित किया।

•	जलवायु परिवर्तन के खिलाफ आंदोलन: भारत में जलवायु परिवर्तन के खिलाफ भी विभिन्न आंदोलन चलाए जा रहे हैं, जिनमें ग्लोबल वार्मिंग और अन्य जलवायु संबंधी मुद्दों के लिए जागरूकता बढ़ाने का प्रयास किया जा रहा है।



</p>
	</div>
	<div><p></p></div>

<div className="card3">
<p classname="mainContent mx-auto">भारत सरकार द्वारा पर्यावरण संरक्षण के क्षेत्र में कई महत्वपूर्ण मिशन और योजनाएं चलाई गई हैं, जो प्राकृतिक संसाधनों के संरक्षण, प्रदूषण नियंत्रण, वन्यजीव संरक्षण, जल संरक्षण, और जलवायु परिवर्तन के सामाजिक और आर्थिक पहलुओं पर ध्यान केंद्रित करते हैं। यहां कुछ महत्वपूर्ण मिशन और योजनाओं का उल्लेख किया गया है:</p>
<p>•	स्वच्छ भारत अभियान (Swachh Bharat Abhiyan): यह अभियान भारत में स्वच्छता, स्वच्छ जल, और स्वस्थता को प्रोत्साहित करने के लिए शुरू किया गया था। इसका मुख्य उद्देश्य गलियों, सड़कों, और जलमार्गों की सफाई, शौचालय निर्माण, और स्वच्छता की संचालन प्रणाली को मजबूत करना था।
•	नमामि गंगे परियोजना (Namami Gange Project): यह परियोजना गंगा नदी के संरक्षण, सुधार, और गंगा के अपवाह को स्वच्छ और स्वस्थ बनाने के लिए शुरू की गई है। इसका मुख्य लक्ष्य गंगा के निर्मलीकरण को प्राप्त करना और इसे जल संभावना में बढ़ावा देना है।
•	जल जीवन मिशन (Jal Jeevan Mission): यह मिशन भारतीय ग्रामीण क्षेत्रों में प्रत्येक घर को सुरक्षित, पर्याप्त, और स्वच्छ जल पहुंचाने का उद्देश्य रखता है। इसका मुख्य लक्ष्य है कि 2024 तक भारत के हर गाँव में पानी का प्रयास हो।
•	ग्रीन इंडिया मिशन: इस मिशन का उद्देश्य भारत में वृक्षारोपण को बढ़ावा देना है, जिससे पर्यावरण संरक्षण, वन्यजीव संरक्षण, और वायु और पानी के प्रदूषण को कम किया जा सके।
•	अतल पेंच (Atal Bhujal Yojana): यह योजना जल संसाधनों के प्रबंधन और संरक्षण के लिए शुरू की गई है, विशेष रूप से भूमि से जुड़े जल स्रोतों के लिए।




</p>
</div>

	</div>


</div>
);
};

export default Page6