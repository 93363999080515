import React from 'react'
import './App.css'
import Header from './components/Header/Header.js'
// import { Button } from "reactstrap";

import 'bootstrap/dist/css/bootstrap.css'
import Page1 from "./Pages/Page1/Page1";
import Page2 from "./Pages/Page2/Page2";
import Page3 from "./Pages/Page3/Page3";
import Page4 from "./Pages/Page4/Page4";
import Page5 from "./Pages/Page5/Page5";
import Page6 from "./Pages/Page6/Page6";
import Body from './Pages/HomePage/Body'
import AuthorPage from './Pages/ProfilePage/Profile'
// import Pagination from "./components/Pagination/Pagination"

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import PostPage from './Pages/PostPage/PostPage'
import MostLikedPost from './Pages/MostLikedPost/MostLikedPost'
import NavigationBar from './components/NavBar/NavigationBar'
import Footer from './components/Footer/Footer'

function App() {
  return (
    <div className="App" id="outer-container" >
        <div id="page-wrap">
      <Router>
     
     
      <Header pageWrapId={'page-wrap'} outerContainerId={'outer-container'}  />
        <Switch>
          <Route exact path="/" component={Body} />
          <Route path="/page1" component={Page1} />
         
          <Route path="/page2" component={Page2} />
          <Route path="/page3" component={Page3} />
          <Route path="/page4" component={Page4} />
          <Route path="/page5" component={Page5} />
          <Route path="/page6" component={Page6} />
          <Route exact path="/MostLikedPost" component={MostLikedPost} />
          <Route exact path="/MostCommentPost" component={MostLikedPost} />
          <Route exact path="/:pageNo" component={Body} />
          <Route exact path="/profile/:authorId" component={AuthorPage} />
          <Route exact path="/Post/:postId" component={PostPage} />
        </Switch>
        <Footer />
        {/* <Body/> */}
      </Router>
    </div></div>
  )
}

export default App
